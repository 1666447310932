import React from "react";
import projectsGalaryData from './projects-data'
import ProjectCard from "./project-card";

// const StyledCom = styled.div`
    
// `;

function ProjectsList() {
	const Cards =  projectsGalaryData.map(project => {
		return (
			<ProjectCard beforeImage={project.imageUrl1} afterImage={project.imageUrl2} id={project.id} title={project.title} />
		)
	});

	return (
		<>{Cards}</>
	)
}

export default ProjectsList;