export default [
    {
        id: '1',
        title: 'Kitchen',
        imageUrl1 : './images/projects-galary/kitchen-v2-before.jpg',
        imageUrl2 : './images/projects-galary/kitchen-v2-after.jpg',
    },
    {
        id: '2',
        title: 'Bathroom',
        imageUrl1 : './images/projects-galary/bathroom-v2-before.jpg',
        imageUrl2 : './images/projects-galary/bathroom-v2-after.jpg',
    },
    {
        id: '3',
        title: 'Kitchen',
        imageUrl1 : './images/projects-galary/kitchen-before.jpg',
        imageUrl2 : './images/projects-galary/kitchen-after.jpg',
    },
    {
        id: '4',
        title: 'Bathroom',
        imageUrl1 : './images/projects-galary/bathroom-before.webp',
        imageUrl2 : './images/projects-galary/bathroom-after.webp',
    },
    {
        id: '5',
        title: 'Decks',
        imageUrl1 : './images/projects-galary/bohlman-before.jpg',
        imageUrl2 : './images/projects-galary/bohlman-after.jpg',
    },
    {
        id: '6',
        title: 'Flooring',
        imageUrl1 : './images/projects-galary/flooring-before.jpg',
        imageUrl2 : './images/projects-galary/flooring-after.jpg',
    },
    {
        id: '7',
        title: 'Basement',
        imageUrl1 : './images/projects-galary/basement-before.png',
        imageUrl2 : './images/projects-galary/basement-after.png',
    },
    
]