import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';

import { useMyContext } from '../components/context';
import projectsData from "./projects-data";
import StartJourney from "./start-journey";

const StyledCom = styled.div`
	margin-top: 100px;

`;


const ProjectDetailPage =() => {
	const { setActiveItem } = useMyContext();
	setActiveItem('projects');

	const { id } = useParams(); 
	console.log(id);

	const tService = projectsData.find((serv) => serv.id === id);

	if (!tService) {
		return <div>Service not found</div>;
	}
	let beforeImage = {
		imageUrl: tService.imageUrl1
	};
	const afterImage = {
		imageUrl: tService.imageUrl2
	};
  return (
	  <StyledCom>
			<section className='section page-content'>
				<div className='container'>
					<ReactBeforeSliderComponent firstImage={afterImage} secondImage={beforeImage} />
				</div>
			</section>
			<StartJourney />
		</StyledCom>
  );
}

export default ProjectDetailPage;